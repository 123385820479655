// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use '@angular/material' as mat;
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$better-saver-primary: mat.define-palette(mat.$indigo-palette);
$better-saver-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$better-saver-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$better-saver-theme: mat.define-light-theme($better-saver-primary, $better-saver-accent, $better-saver-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($better-saver-theme);

/* You can add global styles to this file, and also import other style files */
/*
@use '@angular/material' as mat;
@import "assets/styles/non-styles/colours/colours";
@import "./assets/styles/non-styles/font";
@import "./assets/styles/non-styles/margin";
@import "assets/styles/base.scss";
*/
html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "oktaneue", "Inter";
  font-size: 16px;
}

.dialog-bg-blue {
  background: #E9F6FA;
  opacity: 95% !important;
}

@font-face {
  font-family: 'oktaneue';
  src: url('assets/fonts/OktaNeue-Regular.woff2') format('woff2'),
       url('assets/fonts/OktaNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}